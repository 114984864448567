<template>
  <validation-provider v-slot="{ errors }" rules="" name="user.combo">
    <v-autocomplete
      ref="user"
      :dense="dense"
      v-model="selected"
      :items="items"
      v-bind="$attrs"
      item-value="id"
      :item-text="item => $options.filters.userName(item)"
      :loading="isLoading"
      :label="$t(label)"
      :outlined="outlined"
      :multiple="multiple"
      :deletable-chips="multiple"
      :error-messages="errors"
      auto-select-first
      :menu-props="{ auto: true, overflowY: true, disableKeys:false, closeOnClick: true , closeOnContentClick: true}"
      return-object
      hide-details="true"
    >
    <template slot="selection" slot-scope="{ item }">
      <v-chip small close @click:close="remove(item)"> {{item | userName }}</v-chip>
    </template>
      <template slot="item" slot-scope="{ item }">
        <v-row class="ma-2" align="center">
        <user-avatar :user="item"></user-avatar>

          <div>
        {{ item | userName }}
        <v-chip small v-for="tag, index in item.tags" :key="index" :color="tag.color" class="mx-2">{{ tag.title }}</v-chip>
        </div>
        <div v-if="workInfo(item)">
        <v-icon small class="ml-4 mr-1">
          mdi-briefcase-outline
        </v-icon>
        {{ workInfo(item) }}
      </div>
      </v-row>
      </template>
    </v-autocomplete>
  </validation-provider>
</template>
<script>
import UserAvatar from './UserAvatar.vue'

export default {
  components: { UserAvatar },
  name: "UsersSelect",

  beforeMount() {
    this.isLoading = true
    this.users.then(data => {
      this.items = data
      this.isLoading = false
    })
  },
  data: () => ({
    selected: null,
    isLoading: false,
    internalSearch: null,
    items: [],
  }),
  props: {
    label : {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    autocommit: {
      type: Boolean,
      default: false,
    },
    searchInput: {
      default: undefined
    },
    users: {
      type: Promise,
      default: () => new Promise((resolve) => {
        resolve([])
      })
    },
    usersSelected: {
      type: Array,
      default: null,
    },
  },
  watch: {
    internalSearch(value) {
      this.$emit('update:searchInput', value)
    },
    users(value) {
      this.isLoading = true
      value.then(data => {
        this.items = data
        this.isLoading = false
      })
    },
    usersSelected(value) {
      this.selected = value;
    },
    selected(value) {
      this.$emit("user-selected", value);
    }
  },
  methods: {
    workInfo(user) {
      if (user?.job_title && user?.organization) {
        return this.$t("user.work.information", {
          job_title: user.job_title,
          organization: user.organization
        })
      } else if (user?.job_title) {
        return user.job_title
      } else if (user?.organization) {
        return user.organization
      } else {
        return ""
      }
    },
    remove(item) {
      // const index = this.selected.indexOf(item)
      const index = this.selected.findIndex(object => {
        return object.id === item.id;
      });
      console.log('remove:>> ', item, index, this.selected);
      if (index >= 0) this.selected.splice(index, 1)
    },
  },
}
</script>