import Vue from 'vue'
import Vuex from 'vuex'
import { IS_DEBUG } from '@/util/globals'
import pathify from '@/plugins/vuex-pathify'
// import { get } from '@/plugins/vuex-pathify'

// Modules
import * as modules from './modules'
import { IS_DEV } from '../util/globals'

Vue.use(Vuex)

const HOLD_REFRESHING = true // Hold refreshing data - only for development
// Refreshing data time
const REFRESHING_DATA_TIME = HOLD_REFRESHING && IS_DEV ? 1000000 : 5000 // 5 seconds or 1000 seconds

const store = new Vuex.Store({
  modules,
  plugins: [
    pathify.plugin,
  ],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  strict: IS_DEBUG
})

store.subscribe(mutation => {
  if (!mutation.type.startsWith('user/')) return
  store.dispatch('user/update', mutation)
})

store.dispatch('app/init')
store.dispatch('auth/init')
store.dispatch('payments/init');

export default store

// Refresh function
const refreshData = (store, refreshInterval) => {
  const isAutorized = store.state.auth.authorized
  if (!isAutorized) {
    return
  }
  const currentTime = Date.now()
  const lastFetched = store.state.dashboard.lastFetch
  if (!lastFetched || (currentTime - lastFetched) > refreshInterval) {
    store.dispatch('dashboard/fetchPatrols')
    store.state.dashboard.lastFetch = currentTime
  }
}

// Call refresh function with setInterval

setInterval(() => {
  refreshData(store, REFRESHING_DATA_TIME) // Refresh every 5 second
}, 1000) // Check every second

export const ROOT_DISPATCH = Object.freeze({ root: true })